import React from 'react';

export const TableHeader = () => (
    <thead className="kt-datatable__head">
        <tr className="kt-datatable__row">
            <th width="80px" className="kt-datatable__cell"><span>ID</span></th>
            <th className='kt-datatable__cell yookassa_table_name'><span>Название</span></th>
            <th width="auto" className="kt-datatable__cell"><span>Секретный ключ</span></th>
            <th className='kt-datatable__cell yookassa_date'><span>Дата создания</span></th>
            <th style={{ textAlign: 'right' }} className='kt-datatable__cell table_action'><span>Действия</span></th>
        </tr>
    </thead>
);