import React, { Component } from 'react';
import Input from '../utils/Input';
import Switch from '../utils/Switch';
import DateInput from '../utils/DateInput';
import Select from 'react-select';
import moment from 'moment';
import { deliveryType, promotionType } from './constants';
import { isNumber } from 'lodash';


class EditForm extends Component {
    componentDidMount() {
        this.props.setDetailPromotion(this.props.edited);
    }

    componentWillUnmount() {
        this.props.setEditedPromotion(null);
        this.props.setDetailPromotion(null);
    }

    onChangeEdit = (field, value) => {
        const updated = {
            ...this.props.edited,
            [field]: value
        };

        if (field === 'cities' && !value) {
            updated.cities = [];
        }

        if (field === 'type') {
            updated.persent = 0;
            updated.goods = null;
            updated.category = null;
            updated.value = null;
        }

        this.props.setEditedPromotion(updated);
    }

    cancelEdit = () => {
        this.props.setEditedPromotion(null);
    }

    isValidAdd() {
        const { edited } = this.props;
        if (!edited.name || !edited.minPrice) {
            return false
        }

        if (edited.type === 'order' || edited.type === 'category') {
            if (!edited.value) return false;
        }

        if (edited.type === 'goods') {
            if (!edited.goods || edited.goods.length === 0) return false;
            if (edited.goods.some(item => !item.sale)) return false;
        }

        if (edited.type === 'addGoods') {
            if (!edited.goods || edited.goods.length === 0) return false;
            if (edited.goods.some(item => !isNumber(item.sale))) return false;
            if (edited.goods.some(item => !item.article)) return false;
        }

        if (edited.type === 'category') {
            if (!edited.category) return false;
        }

        if ((edited.startTimeDate && !edited.endTimeDate) || (!edited.startTimeDate && edited.endTimeDate)) {
            return false;
        }

        return true;
    }

    isValidSaveEdit() {
        if (!this.isValidAdd()) return false;

        return true;
    }

    updatePromotion = () => {
        this.props.updatePromotion(this.props.edited.id, this.props.edited).then(result => {
            if (result) {
                this.props.fetchPromotions();
                this.props.setEditedPromotion(null);
            }
        });
    }

    onChangeGoods = (selected) => {
        const data = selected ? selected.map(item => {
            if (item.sale === null || item.sale === undefined) {
                return { ...item, sale: null, persent: 0 };
            }
            return item;
        }) : [];
        this.onChangeEdit('goods', data);
    }

    onChangeGoodsItem = (field, index, value) => {
        const goods = [...this.props.edited.goods];
        goods[index][field] = value
        this.onChangeEdit('goods', goods);
    }

    render() {
        const { edited, item } = this.props;

        return (
            <div className="kt-grid  kt-wizard-v1 kt-wizard-v1--white" id="kt_apps_projects_add" data-ktwizard-state="first">
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
                    <form className="kt-form">
                        <div className="kt-wizard-v1__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                            <div className="kt-heading kt-heading--md">Редактирование акции</div>
                            <div className="kt-section kt-section--first">
                                <div className="kt-wizard-v1__form">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="kt-section__body">

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Название*</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <Input onChange={(e) => this.onChangeEdit('name', e.target.value)} disableGroup={true} value={edited.name}/>
                                                    </div>
                                                </div>              

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Город</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <Select
                                                            isLoading={this.props.loadingCity}
                                                            isDisabled={this.props.loadingCity}
                                                            menuPortalTarget={document.body}
                                                            closeMenuOnSelect={true}
                                                            isMulti
                                                            value={edited.cities.map(item => ({
                                                                value: item.id || item.value,
                                                                label: item.name || item.label
                                                            }))}
                                                            onChange={(selected) => this.onChangeEdit('cities', selected)}
                                                            options={
                                                                this.props.cities.map(item => ({
                                                                    value: item.id,
                                                                    label: item.name
                                                                }))
                                                            }
                                                            placeholder="Любой"
                                                            noOptionsMessage={() => 'Нет городов'}
                                                        />
                                                    </div>
                                                </div>

                                                <hr className="hrLine"/>

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Тип</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <select onChange={(e) => this.onChangeEdit('type', e.target.value)} value={edited.type} className="form-control">
                                                            {
                                                                Object.keys(promotionType).map((key) => (
                                                                    <option key={key} value={key}>{promotionType[key]}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                {
                                                    edited.type === 'goods' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Список товаров</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <Select
                                                                    isLoading={this.props.loadingGoods}
                                                                    isDisabled={this.props.loadingGoods}
                                                                    menuPortalTarget={document.body}
                                                                    isMulti
                                                                    isSearchable
                                                                    closeMenuOnSelect={true}
                                                                    options={this.props.goods}
                                                                    placeholder="Товары"
                                                                    noOptionsMessage={() => 'Нет товаров'}
                                                                    onChange={this.onChangeGoods}
                                                                    value={edited.goods}
                                                                />

                                                                {
                                                                    edited.goods && edited.goods.length > 0 && (
                                                                        <div style={{ marginTop: 15 }}>
                                                                            {
                                                                                edited.goods.map((good, index) => (
                                                                                    <div style={{ marginTop: 5, alignItems: 'center' }} className="row">
                                                                                        <div className="col-xl-3 col-lg-3">{good.label}</div>
                                                                                        <div className="col-lg-4 col-xl-4">
                                                                                            <Input placeholder="Скидка" onChange={(e) => this.onChangeGoodsItem('sale', index , e.target.value ? +e.target.value : null)} type="number" disableGroup={true} value={good.sale}/>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }} className="col-lg-4 col-xl-4">  
                                                                                            <Switch nomargin={true} onChange={(value) => this.onChangeGoodsItem('persent', index, +value)} checked={!!good.persent}/>
                                                                                            <div style={{ marginLeft: 10 }}>Процент</div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    edited.type === 'addGoods' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Список товаров</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <Select
                                                                    isLoading={this.props.loadingGoods}
                                                                    isDisabled={this.props.loadingGoods}
                                                                    menuPortalTarget={document.body}
                                                                    isMulti
                                                                    isSearchable
                                                                    closeMenuOnSelect={true}
                                                                    options={this.props.goods}
                                                                    placeholder="Товары"
                                                                    noOptionsMessage={() => 'Нет товаров'}
                                                                    onChange={this.onChangeGoods}
                                                                    value={edited.goods}
                                                                />

                                                                {
                                                                    edited.goods && edited.goods.length > 0 && (
                                                                        <div style={{ marginTop: 15 }}>
                                                                            {
                                                                                edited.goods.map((good, index) => (
                                                                                    <div style={{ marginTop: 5, alignItems: 'center' }} className="row">
                                                                                        <div className="col-xl-3 col-lg-3">{good.label}</div>
                                                                                        <div className="col-lg-4 col-xl-4">
                                                                                            <Input placeholder="Цена" onChange={(e) => this.onChangeGoodsItem('sale', index , e.target.value ? +e.target.value : null)} type="number" disableGroup={true} value={good.sale}/>
                                                                                        </div>
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }} className="col-lg-4 col-xl-4">  
                                                                                             <Input placeholder="Артикул" onChange={(e) => this.onChangeGoodsItem('article', index, e.target.value)} disableGroup={true} value={good.article}/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    edited.type === 'category' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Категория</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <Select
                                                                    isLoading={this.props.loadingGoods}
                                                                    isDisabled={this.props.loadingGoods}
                                                                    menuPortalTarget={document.body}
                                                                    isSearchable
                                                                    closeMenuOnSelect={true}
                                                                    options={this.props.categories}
                                                                    placeholder="Категории"
                                                                    noOptionsMessage={() => 'Нет категорий'}
                                                                    onChange={(selected) => this.onChangeEdit('category', selected)}
                                                                    value={edited.category}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    edited.type === 'order' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Значение*</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <Input onChange={(e) => this.onChangeEdit('value', +e.target.value)} type="number" disableGroup={true} value={edited.value}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    edited.type === 'order' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Процент</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <Switch nomargin={true} onChange={(value) => this.onChangeEdit('persent', +value)} checked={!!edited.persent}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    edited.type === 'category' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Значение*</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <Input onChange={(e) => this.onChangeEdit('value', +e.target.value)} type="number" disableGroup={true} value={edited.value}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    edited.type === 'category' && (
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Процент</label>
                                                            <div className="col-lg-9 col-xl-9">
                                                                <Switch nomargin={true} onChange={(value) => this.onChangeEdit('persent', +value)} checked={!!edited.persent}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <hr className="hrLine"/>

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Период действия</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <div className="dateBlock">
                                                            <DateInput
                                                                placeholderText="Начало периода"
                                                                selected={edited.startDate && moment(edited.startDate).toDate()}
                                                                onChange={(date) => this.onChangeEdit('startDate', date)}
                                                            />
                                                            <div>-</div>
                                                            <DateInput
                                                                placeholderText="Конец периода"
                                                                selected={edited.endDate && moment(edited.endDate).toDate()}
                                                                onChange={(date) => this.onChangeEdit('endDate', date)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Часы работы</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <div className="dateBlock">
                                                            <DateInput
                                                                placeholderText="Начало периода"
                                                                showTimeSelectOnly
                                                                dateFormat="HH:mm"
                                                                selected={edited.startTimeDate && moment(edited.startTimeDate).toDate()}
                                                                onChange={(date) => this.onChangeEdit('startTimeDate', date)}
                                                            />
                                                            <div>-</div>
                                                            <DateInput
                                                                placeholderText="Конец периода"
                                                                showTimeSelectOnly
                                                                dateFormat="HH:mm"
                                                                selected={edited.endTimeDate && moment(edited.endTimeDate).toDate()}
                                                                onChange={(date) => this.onChangeEdit('endTimeDate', date)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <hr className="hrLine"/>

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Сумма заказа*</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <Input onChange={(e) => this.onChangeEdit('minPrice', +e.target.value)} type="number" disableGroup={true} value={edited.minPrice}/>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Тип доставки</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <select onChange={(e) => this.onChangeEdit('delivery', e.target.value)} value={edited.delivery} className="form-control">
                                                            {
                                                                Object.keys(deliveryType).map((key) => (
                                                                    <option key={key} value={key}>{deliveryType[key]}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Предыдущий заказ (дней)</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <Input onChange={(e) => this.onChangeEdit('prevOrderDays', +e.target.value)} type="number" disableGroup={true} value={edited.prevOrderDays}/>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group row form-group-last">
                                                    <label className="col-xl-3 col-lg-3 col-form-label">Включен</label>
                                                    <div className="col-lg-9 col-xl-9">
                                                        <Switch nomargin={true} onChange={(value) => this.onChangeEdit('active', +value)} checked={!!edited.active}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-form__actions">
                            <button onClick={this.cancelEdit} style={{ marginRight: 10 }} className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                                Отмена
                            </button>
                            <button onClick={this.updatePromotion} disabled={!this.isValidSaveEdit(edited, item)} className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                                Сохранить
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default EditForm;