import { isEmpty } from "lodash";
import React, { Component } from "react";
import cn from 'classnames';
class Categories extends Component {
    render() {
        return(
            <div className="kt-todo__nav">
                <ul className="kt-nav" style={{ padding: 0 }}>
                    {isEmpty(this.props.categories) ? <li>Категорий нет</li> : this.props.categories.map((category) => (
                        !category.inner &&
                            <li
                            onClick={this.props.onSelectCategory(category.id)}
                            key={`${category.id}_category`}
                            className={cn('kt-nav__item', { 'kt-nav__item--active': this.props.activeCategory === category.id })}>
                            <span className='kt-nav__link neworder' data-action="list">
                                <span className='kt-nav__link-text neworder'>{category.title}</span>
                            </span>                                
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
export default Categories