import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUIdefault } from '../../action/UImobile';

export default function Blackout() {
    const dispatch = useDispatch();
    const openSidebar = useSelector(state => state.UImobile.openSidebar);
        return (
            <div 
            className={`kt-aside_blackout${openSidebar ? ' open' : ''}`}
            onClick={() => {
                dispatch(setUIdefault());
            }}
            >
            </div>
        )
}