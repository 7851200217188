import React from 'react';

export const TableHeader = () => (
    <thead className="kt-datatable__head">
        <tr className="kt-datatable__row">
            <th className='kt-datatable__cell good_id'><span>ID</span></th>
            {window.innerWidth > 830 && <th className='kt-datatable__cell good_preview'><span>Превью</span></th>}
            <th className='kt-datatable__cell good_name'><span>Название</span></th>
            <th className='kt-datatable__cell good_info'><span>Цена</span></th>
            <th className='kt-datatable__cell good_info'><span>Старая цена</span></th>
            <th className='kt-datatable__cell good_info article'><span>Артикул</span></th>
            <th className='kt-datatable__cell good_info'><span>Вес/Объем</span></th>
            <th style={{ textAlign: 'right' }} className='kt-datatable__cell table_action'><span>Действия</span></th>
        </tr>
    </thead>
);