import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { fetchCategories, setActiveCategory, setActiveModalAction } from '../../../action';
import Loader from '../../../components/utils/Loader';
import { fromStore } from '../../../selectors';
import { getImageUrl } from '../../../utils/common';
import { isEmpty } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { styles } from '../styles';

export function CategoryList() {
    const dispatch = useDispatch();
    const loading = useSelector(fromStore.loaderCategoriesSelector);
    const categories = useSelector(fromStore.categoryListSelector);
    const activeCategory = useSelector(fromStore.activeCategorySelector);
    const scrollRef = useRef(null);
    const [hasScrollLeft, setHasScrollLeft] = useState(false);
    const [hasScrollRight, setHasScrollRight] = useState(false);

        const updateScrollIndicators = () => {
            const current = scrollRef.current;
            if (current) {
                const scrollLeft = current.scrollLeft;
                const scrollWidth = current.scrollWidth;
                const clientWidth = current.clientWidth;
        
                setHasScrollLeft(scrollLeft > 0);
                setHasScrollRight(scrollLeft + clientWidth < scrollWidth);
            }
        };
    
        useEffect(() => {
            updateScrollIndicators();
            window.addEventListener('resize', updateScrollIndicators);
            return () => {
                window.removeEventListener('resize', updateScrollIndicators);
            };
        }, [loading]);
    
    
    const onSelectCategory = (id) => () => {
        dispatch(setActiveCategory(id));
        document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
    };

    const onOpenModalCategory = (id) => (e) => {
        e.stopPropagation();
        const categoryId = id || 'new';
        dispatch(setActiveModalAction({ field: 'category', value: categoryId }));
    };

    useEffect(() => {
        isEmpty(categories) && dispatch(fetchCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    if (loading) {
        if(window.innerWidth > 1024)
            return <div style={styles.loading}><Loader text='Загружаю категории'/></div>;

        return (
            <div className="kt-todo__nav">
                <ul className="kt-nav category" style={{ padding: 0, justifyContent: 'center' }}>
                    <div  div style={styles.loading}><Loader text='Загружаю категории'/></div>
                </ul>
            </div>
        );
    }

        return (
            <>
                <div className="kt-todo__nav" style={{ overflow: 'hidden' }}>
                    {hasScrollLeft && 
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            width: '10px',
                            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0))',
                            zIndex: 1
                        }} />
                    }
                    <ul 
                    ref={scrollRef}
                    className="kt-nav category" 
                    style={{ padding: 0 }}
                    onScroll={updateScrollIndicators}
                    >
                        {isEmpty(categories) ? <li>Категорий нет</li> : categories.map((category) => (
                            <li
                                onClick={onSelectCategory(category.id)}
                                key={`${category.id}_category`}
                                className={cn('kt-nav__item goods', { 'kt-nav__item--active': activeCategory === category.id, 'inner-cat': !!category.inner })}>
                                <span className='kt-nav__link goods' data-action="list">
                                    <img className='categoty_image' src={getImageUrl(category.imagePrev)} alt=""/>
                                    <span className="kt-nav__link-text goods">{category.title}</span>
                                    {window.innerWidth > 1024 && <button data-tip="Редактировать"onClick={onOpenModalCategory(category.id)} className="btn btn-sm btn-clean btn-icon btn-icon-lg">
                                        <i className="la la-edit"></i>
                                    </button>}
                                </span>
                                
                            </li>
                        ))}
                    </ul>
                    {hasScrollRight && 
                        <div style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            width: '10px',
                            background: 'linear-gradient(to left, rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0))',
                            zIndex: 1
                        }} />
                    }
                </div>
                {window.innerWidth > 1024 && <button onClick={onOpenModalCategory()} className="btn btn-brand btn-elevate btn-icon-sm" style={{ marginTop: '20px' }}>
                    <i className="la la-plus"></i>
                    Добавить категорию
                </button>}
                <ReactTooltip
                    effect="solid"
                    type="dark"
                    place="top"
                />
            </>
        );
}